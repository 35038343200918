<template>
  <div>
    <v-row align="center">
      <v-col cols="auto" class="pr-12">
        <h3>Contacts</h3>
      </v-col>
      <v-col>
        <v-row align="center" justify="end" no-gutters>
          <v-btn small depressed color="accent" class="mr-2" @click="addContactForm.dialog = true">
            <v-icon left>mdi-plus</v-icon>Add Contact
          </v-btn>
        </v-row>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-data-table
          :headers="tableHeaders"
          :items="customer.contacts"
          show-expand
          no-data-text="No contacts found"
        >
          <template v-slot:item.actions="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  x-small
                  depressed
                  color="blue lighten-4 blue--text"
                  v-on="on"
                  class="mr-2"
                  @click="openEditContact(item.id)"
                >
                  <v-icon x-small>mdi-pencil</v-icon>
                </v-btn>
              </template>
              <span>Edit</span>
            </v-tooltip>
          </template>
          <template v-slot:expanded-item="{ item }">
            <div class="pa-4">
              <h4>Notes</h4>
              <div>{{ item.notes }}</div>
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-dialog v-model="addContactForm.dialog" max-width="600px">
      <v-card>
        <v-form @submit.prevent="addContact" method="post">
          <v-card-title>
            <span class="headline">Add New Contact</span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="Name *"
                  type="text"
                  v-model="addContactForm.name"
                  outlined
                  dense
                  :error="addContactForm.errors.hasOwnProperty('name')"
                  :error-messages="addContactForm.errors['name']"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="Email"
                  type="email"
                  v-model="addContactForm.email"
                  outlined
                  dense
                  :error="addContactForm.errors.hasOwnProperty('email')"
                  :error-messages="addContactForm.errors['email']"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="Phone Number"
                  type="text"
                  v-model="addContactForm.phone_number"
                  outlined
                  dense
                  class
                  :error="
                      addContactForm.errors.hasOwnProperty('phone_number')
                    "
                  :error-messages="addContactForm.errors['phone_number']"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  label="Notes"
                  v-model="addContactForm.notes"
                  outlined
                  dense
                  :error="addContactForm.errors.hasOwnProperty('notes')"
                  :error-messages="addContactForm.errors['notes']"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="secondary" text @click="addContactForm.dialog = false">Close</v-btn>
            <v-btn color="accent" text type="submit" :loading="addContactForm.loading">Save</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-dialog v-model="editContactForm.dialog" max-width="600px">
      <v-card>
        <v-form @submit.prevent="editContact" method="post">
          <v-card-title>
            <span class="headline">Edit Customer Contact</span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-row>
              <v-col cols="6">
                <v-text-field
                  label="Customer Name *"
                  type="text"
                  v-model="editContactForm.name"
                  outlined
                  dense
                  :error="editContactForm.errors.hasOwnProperty('name')"
                  :error-messages="editContactForm.errors['name']"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  label="Phone Number"
                  type="text"
                  v-model="editContactForm.phone_number"
                  outlined
                  dense
                  :error="
                      editContactForm.errors.hasOwnProperty('phone_number')
                    "
                  :error-messages="editContactForm.errors['phone_number']"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  label="Company Email"
                  type="text"
                  v-model="editContactForm.email"
                  outlined
                  dense
                  class
                  :error="editContactForm.errors.hasOwnProperty('email')"
                  :error-messages="editContactForm.errors['email']"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  label="Notes"
                  v-model="editContactForm.notes"
                  outlined
                  dense
                  class
                  :error="editContactForm.errors.hasOwnProperty('notes')"
                  :error-messages="editContactForm.errors['notes']"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="secondary" text @click="editContactForm.dialog = false">Close</v-btn>
            <v-btn color="accent" text type="submit" :loading="editContactForm.loading">Save</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    customer: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      searchTerm: "",
      tableHeaders: [
        { text: "Name", value: "name" },
        { text: "Phone Number", value: "phone_number" },
        { text: "Email", value: "email" },
        { text: "Actions", value: "actions", align: "right" },
        { text: "", value: "data-table-expand" },
      ],
      addContactForm: {
        dialog: false,
        name: null,
        email: null,
        phone_number: null,
        notes: null,
        loading: false,
        errors: {},
      },
      editContactForm: {
        dialog: false,
        id: null,
        name: null,
        email: null,
        phone_number: null,
        notes: null,
        loading: false,
        errors: {},
      },

      relationshipUpdating: null,
    };
  },

  computed: {
    customerContacts() {
      let customerContacts = this.$store.state.customers["customer_contacts"];
      return customerContacts;
    },
  },

  methods: {
    addContact: function () {
      const customerId = this.$route.params.customerId;
      const appId = this.$route.params.id;
      const name = this.addContactForm.name;
      const phone_number = this.addContactForm.phone_number;
      const email = this.addContactForm.email;
      const notes = this.addContactForm.notes;

      this.addContactForm.loading = true;

      this.$store
        .dispatch("customers/addContact", {
          appId,
          name,
          email,
          phone_number,
          notes,
          customerId,
        })
        .then(() => {
          this.addContactForm = {
            dialog: false,
            name: null,
            email: null,
            phone_number: null,
            notes: null,
            customerId: null,
            loading: false,
            errors: {},
          };
        })
        .catch((err) => {
          this.addContactForm.errors = err.response.data.errors;
          this.addContactForm.loading = false;
        });
    },
    openEditContact: function (id) {
      const contact = this.customer.contacts.find((c) => c.id === id);

      this.editContactForm = {
        dialog: true,
        id: id,
        contactId: contact.id,
        name: contact.name,
        phone_number: contact.phone_number,
        email: contact.email,
        notes: contact.notes,
        loading: false,
        errors: {},
      };
    },
    editContact: function () {
      const appId = this.$route.params.id;
      const customerId = this.$route.params.customerId;
      const contactId = this.editContactForm.contactId;

      const name = this.editContactForm.name;
      const phone_number = this.editContactForm.phone_number;
      const email = this.editContactForm.email;
      const notes = this.editContactForm.notes;

      this.editContactForm.loading = true;

      this.$store
        .dispatch("customers/editContact", {
          appId,
          customerId,
          contactId,
          name,
          email,
          phone_number,
          notes,
        })
        .then(() => {
          this.editContactForm = {
            dialog: false,
            id: null,
            contactId: null,
            name: null,
            email: null,
            phone_number: null,
            notes: null,
            loading: false,
            errors: {},
          };
        })
        .catch((err) => {
          this.editContactForm.errors = err.response.data.errors;
          this.editContactForm.loading = false;
        });
    },
  },
};
</script>
